import { ObjectShape, TypeOfShape } from 'yup/lib/object'
import { AnyObject } from 'yup/lib/types'

import registrationSchema from "./list/registration"

const schemas = {
    registration: registrationSchema
}



export default schemas